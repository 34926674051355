<template >
    <div>
        <RemindersList/>
</div>
</template>
<script>

export default {
    name:"Reminders",
    components: {
        RemindersList: () => import('@/components/reminders/List')
    },
   
    
}
</script>
<style >
    
</style>